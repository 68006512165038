import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import DevNextConfig from '../../../assets/config/app-config.dev-next.json';
import { Injectable } from '@angular/core';
import { AppConfigInterface } from '../interfaces/app-config.interface';

@Injectable()
export class ConfigService {
  static config: AppConfigInterface;

  public static loadConfiguration(
    http: HttpClient
  ): () => Promise<AppConfigInterface> {
    console.log('loading config');
    return () => http.get<AppConfigInterface>('assets/config/app-config.json')
      .pipe(
        catchError(() => {
          console.warn(
            'No app-config.json found, continue with app-config.dev-next.json'
          );
          return of(DevNextConfig);
        }),
        tap(config => {
          ConfigService.config = config;
          console.log('config loaded for environment', config.environment);
        })
      )
      .toPromise();
  }

  static getConfig(): AppConfigInterface {
    return ConfigService.config;
  }
}
