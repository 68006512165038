import {
  Component, ElementRef, OnInit, ViewChild
} from '@angular/core';
import { DevicesService } from '../../shared/services/devices.service';
import { Router } from '@angular/router';
import { HEIGHT, LOCAL_STORAGE_SELECTED_CAMERA_KEY, WIDTH } from '../../shared/constants';

@Component({
  selector: 'app-remote-monitoring-setup',
  templateUrl: './remote-monitoring-setup.component.html',
  styleUrls: ['./remote-monitoring-setup.component.scss']
})
export class RemoteMonitoringSetupComponent implements OnInit {
  @ViewChild('video', { static: true }) video: ElementRef;

  cameraOptions: never[];
  mediaStream: MediaStream;
  selectedCamera: any = null;

  readonly WIDTH = WIDTH;
  readonly HEIGHT = HEIGHT;

  constructor(
    private devicesService: DevicesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.setupDevices();
  }

  setupDevices(): void {
    this.devicesService.getDevices()
      .subscribe({
        next: (devices) => {
          const videoinputDevices = devices.filter(device => device.kind === 'videoinput');
          if (videoinputDevices.length > 0) {
            this.cameraOptions = videoinputDevices.map((device) => ({
              value: device.deviceId,
              view: device.label.split(' (')[0],
            })) as never[];
          } else {
            this.cameraOptions = [] as never[];
            // eslint-disable-next-line no-alert
            alert('You have no input video device');
            // TODO: show error message non input video devices
            console.error('You have no input video device');
          }
        },
        error: (err) => {
          this.cameraOptions = [] as never[];
          // eslint-disable-next-line no-alert
          alert('You have no input video device');
          // TODO: show error message non input video devices
          console.error(err);
        }
      });
  }

  selectCamera(selectedCamera): void {
    this.selectedCamera = selectedCamera;

    //  Stop the current stream
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => track.stop());
    }

    //  Start the new stream
    const constraints = {
      video: {
        deviceId: { exact: this.selectedCamera },
        width: { ideal: WIDTH },
        height: { ideal: HEIGHT }
      }
    };

    this.devicesService.getMediaStream(constraints).subscribe({
      next: (stream) => {
        this.mediaStream = stream;
        this.video.nativeElement.srcObject = this.mediaStream;
      },
      error: (err) => {
        // eslint-disable-next-line no-alert
        alert('Error getting media stream');
        console.error(err);
      }
    });
  }

  onStartMonitoring() {
    localStorage.setItem(LOCAL_STORAGE_SELECTED_CAMERA_KEY, JSON.stringify(this.selectedCamera));
    this.router.navigate(['/monitor']);
  }
}
