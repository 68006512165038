import {
  AfterViewInit, Component, ElementRef, OnInit, ViewChild
} from '@angular/core';
import { SwanAIService } from '../../shared/services/swan-ai.service';
import { Router } from '@angular/router';
import {
  CAPTURES_MAX_LENGTH, HEIGHT,
  IMG_TYPE,
  LAST_SNAPSHOT_HEIGHT,
  LAST_SNAPSHOT_WIDTH,
  LOCAL_STORAGE_CAPTURES_KEY,
  LOCAL_STORAGE_SELECTED_CAMERA_KEY,
  SNAPSHOT_HEIGHT,
  SNAPSHOT_WIDTH,
  TIMER_TIMEOUT,
  VIDEO_HEIGHT,
  VIDEO_WIDTH, WIDTH,
} from '../../shared/constants';
import { DevicesService } from '../../shared/services/devices.service';
import { AwAttachmentInterface } from '@aw/ads-components/src/interfaces';

@Component({
  selector: 'app-remote-monitoring',
  templateUrl: './remote-monitoring.component.html',
  styleUrls: ['./remote-monitoring.component.scss']
})
export class RemoteMonitoringComponent implements OnInit, AfterViewInit {
  @ViewChild('video')
  video: ElementRef;

  @ViewChild('lastSnapshotCanvas')
  canvas: ElementRef;

  @ViewChild('hiddenVideoCanvas')
  hiddenCanvas: ElementRef;

  selectedCamera: any = null;
  captures: string[] = [];
  error: any = null;
  items: AwAttachmentInterface[] = [];
  openGallery = false;
  activeGalleryItemIndex = 0;

  readonly WIDTH = WIDTH;
  readonly HEIGHT = HEIGHT;
  readonly VIDEO_WIDTH = VIDEO_WIDTH;
  readonly VIDEO_HEIGHT = VIDEO_HEIGHT;
  readonly LAST_SNAPSHOT_WIDTH = LAST_SNAPSHOT_WIDTH;
  readonly LAST_SNAPSHOT_HEIGHT = LAST_SNAPSHOT_HEIGHT;
  readonly SNAPSHOT_WIDTH = SNAPSHOT_WIDTH;
  readonly SNAPSHOT_HEIGHT = SNAPSHOT_HEIGHT;

  private interval: any;

  get videoElement() {
    return this.video?.nativeElement;
  }

  get canvasElement() {
    return this.canvas?.nativeElement;
  }

  get canvasContext() {
    return this.canvas?.nativeElement.getContext('2d');
  }

  get hiddenCanvasElement() {
    return this.hiddenCanvas?.nativeElement;
  }

  get hiddenCanvasContext() {
    return this.hiddenCanvas?.nativeElement.getContext('2d');
  }

  constructor(
    private swanAIService: SwanAIService,
    private devicesService: DevicesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.captures = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CAPTURES_KEY)) || [];
    this.selectedCamera = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SELECTED_CAMERA_KEY)) || null;
  }

  async ngAfterViewInit(): Promise<void> {
    const constraints = {
      video: {
        deviceId: { exact: this.selectedCamera },
        width: { ideal: WIDTH },
        height: { ideal: HEIGHT }
      }
    };

    this.devicesService.getMediaStream(constraints).subscribe({
      next: (stream) => {
        this.videoElement.srcObject = stream;
        this.videoElement.play();
        this.interval = setInterval(() => {
          try {
            this.addSnapshot();
          } catch (e) {
            console.error(e);
          }
        }, TIMER_TIMEOUT);
      },
      error: (err) => {
        // eslint-disable-next-line no-alert
        alert('Error getting media stream');
        console.error(err);
      }
    });
  }

  onEndMonitoring(): void {
    clearInterval(this.interval);
    localStorage.removeItem(LOCAL_STORAGE_SELECTED_CAMERA_KEY);
    this.router.navigate(['/']);
  }

  openSnapshot(index: number): void {
    this.activeGalleryItemIndex = index;
    this.openGallery = true;
  }

  private addSnapshot() {
    const image = this.captureSnapshot();
    const processedSnapshot = this.swanAIService.processSnapshot(); // TODO: pass image to API
    this.drawSnapshot(processedSnapshot, image); // TODO: remove image from params once API is ready
    const base64Image = this.canvasElement.toDataURL(IMG_TYPE);

    if (this.captures.length === CAPTURES_MAX_LENGTH) {
      this.captures.pop();
    }
    this.captures.unshift(base64Image);

    if (!this.openGallery) {
      this.items = this.captures.map((capture, index) => ({
        id: `${index}`,
        fileName: `Snapshot ${index + 1}.jpg`,
        url: capture,
      }));
    }

    localStorage.setItem(LOCAL_STORAGE_CAPTURES_KEY, JSON.stringify(this.captures));
  }

  private captureSnapshot(): string {
    this.hiddenCanvasContext.drawImage(this.videoElement, 0, 0, WIDTH, HEIGHT);
    return this.hiddenCanvasElement.toDataURL(IMG_TYPE);
  }

  private drawSnapshot(snapshot: any, imageSrc: string): void {
    const image = new Image();
    image.src = imageSrc; // TODO: change imageSrc to snapshot.s3Url once API is ready;
    image.onload = () => {
      this.canvasContext.drawImage(image, 0, 0, LAST_SNAPSHOT_WIDTH, LAST_SNAPSHOT_HEIGHT);

      snapshot.patient.forEach((patient: any) => {
        this.drawRectangle(patient, 7);
      });
      snapshot.person.forEach((person: any) => {
        this.drawRectangle(person, 3);
      });
      snapshot.bed.forEach((bed: any) => {
        this.drawRectangle(bed, 2);
      });
      snapshot.rail.forEach((rail: any) => {
        this.drawRectangle(rail, 1);
      });
    };
  }

  private drawRectangle(obj: any, lineWidth: number): void {
    this.canvasContext.strokeStyle = this.getStrokeColor(obj.Confidence);
    this.canvasContext.lineWidth = lineWidth;
    this.canvasContext.strokeRect(
      obj.BoundingBox.Left,
      obj.BoundingBox.Top,
      obj.BoundingBox.Width,
      obj.BoundingBox.Height
    );
  }

  private getStrokeColor(confidence: number): string {
    let color = 'rgba(255, 255, 255, 0.75)';
    if (confidence >= 90) {
      color = 'rgba(0, 255, 0, 0.75)';
    }
    if (confidence < 90 && confidence >= 60) {
      color = 'rgba(255, 255, 0, 0.75)';
    }
    if (confidence < 60) {
      color = 'rgba(255, 0, 0, 0.75)';
    }
    return color;
  }
}
