import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class DevicesService {
  getDevices(): Observable<MediaDeviceInfo[]> {
    return from(this.requestPermissions()).pipe(
      switchMap(() => from(navigator.mediaDevices.enumerateDevices()))
    );
  }

  getMediaStream(constraints: MediaStreamConstraints): Observable<MediaStream> {
    return from(this.requestPermissions()).pipe(
      switchMap(() => from(navigator.mediaDevices.getUserMedia(constraints)))
    );
  }

  private requestPermissions(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      (navigator as any).getUserMedia(
        {
          video: true,
        },
        () => {
          resolve(true);
        },
        () => {
          reject(new Error('Devices permissions not granted'));
        }
      );
    });
  }
}
