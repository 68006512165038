export const WIDTH = 1280;
export const HEIGHT = 720;
export const LAST_SNAPSHOT_WIDTH = 542;
export const LAST_SNAPSHOT_HEIGHT = 411;
export const VIDEO_WIDTH = 370;
export const VIDEO_HEIGHT = 278;
export const SNAPSHOT_WIDTH = 112;
export const SNAPSHOT_HEIGHT = 84;
export const CAPTURES_MAX_LENGTH = 12;
export const TIMER_TIMEOUT = 1000;
export const LOCAL_STORAGE_CAPTURES_KEY = 'vic-captures';
export const LOCAL_STORAGE_SELECTED_CAMERA_KEY = 'vic-selected-camera';
export const IMG_TYPE = 'image/jpeg';
