<div class="flex m-b3">
  <div class="m-t1">
    <h2>Remote Monitoring Setup</h2>
  </div>
  <div class="flex flex-auto justify-end">
    <div class="select-camera-container m-r3">
      <aw-select *ngIf="(cameraOptions && cameraOptions.length > 0); else no_permisions"
        label="Choose camera"
        [options]="cameraOptions"
        (onChange)="selectCamera($event)">
      </aw-select>
    </div>

    <div class="m-t1">
      <aw-button styleClasses="btn" automationValue="startMonitoringButton" (click)="onStartMonitoring()">
        <span class="material-icons-outlined" aria-hidden="true">screen_share</span>Start Monitoring
      </aw-button>
    </div>
  </div>
</div>
<div class="flex flex-auto justify-center">
  <video #video id="video" class="m-r2 vic-rounded" [width]="WIDTH" [height]="HEIGHT" autoplay muted playsinline></video>
</div>

<ng-template #no_permisions>
  <p>Please allow cameras access</p>
</ng-template>
