<div class="flex m-b3">
  <div class="m-t1">
    <h2>Remote Monitoring</h2>
  </div>
  <div class="flex flex-auto justify-end items-baseline">
    <div class="m-t1">
      <aw-button styleClasses="btn" automationValue="endMonitoringButton" (click)="onEndMonitoring()">
        <span class="material-icons-outlined" aria-hidden="true">screen_share</span>End Monitoring
      </aw-button>
    </div>
  </div>
</div>

<div class="flex justify-between" *ngIf="!error">
  <div class="flex-1"></div>
  <div class="flex-1 m-r1">
    <p>LAST SNAPSHOT</p>
    <canvas #lastSnapshotCanvas id="lastSnapshotCanvas" class="vic-rounded"
            [width]="LAST_SNAPSHOT_WIDTH" [height]="LAST_SNAPSHOT_HEIGHT"></canvas>
  </div>
  <div class="flex-1">
    <div class="right">
      <p class="m-l2">LIVE IMAGE</p>
      <video #video id="video" class="vic-rounded" [width]="VIDEO_WIDTH" [height]="VIDEO_HEIGHT"
             autoplay muted playsinline></video>
      <canvas #hiddenVideoCanvas id="hiddenVideoCanvas"
              [width]="WIDTH" [height]="HEIGHT"
              [hidden]="true"></canvas>
    </div>
  </div>
</div>

<div class="m-y2 hue10-text" *ngIf="error">
  {{ error }}
</div>

<div class="m-y2">
  <p>PREVIOUS SNAPSHOTS</p>
  <div class="flex flex-wrap">
    <div class="m-r2 m-b2" *ngFor="let src of captures; index as i">
      <img class="pointer" [src]="src" [width]="SNAPSHOT_WIDTH" [height]="SNAPSHOT_HEIGHT"
           alt="Click to review image" (click)="openSnapshot(i)"/>
    </div>
  </div>
  <aw-galleria
    [(open)]="openGallery"
    [(activeItemIndex)]="activeGalleryItemIndex"
    [items]="items">
  </aw-galleria>
</div>
