import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RemoteMonitoringSetupComponent } from './components/remote-monitoring-setup/remote-monitoring-setup.component';
import { RemoteMonitoringComponent } from './components/remote-monitoring/remote-monitoring.component';

const routes: Routes = [
  {
    path: '',
    component: RemoteMonitoringSetupComponent
  },
  {
    path: 'monitor',
    component: RemoteMonitoringComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
