import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigService } from './shared/services/config.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RemoteMonitoringSetupComponent } from './components/remote-monitoring-setup/remote-monitoring-setup.component';
import { AwBreadcrumbsModule } from '@aw/ads-components/aw-breadcrumbs';
import { AwSelectModule } from '@aw/ads-components/aw-select';
import { FormsModule } from '@angular/forms';
import { AwButtonModule } from '@aw/ads-components/aw-button';
import { DevicesService } from './shared/services/devices.service';
import { RemoteMonitoringComponent } from './components/remote-monitoring/remote-monitoring.component';
import { AwGalleriaModule } from '@aw/ads-components/aw-galleria';

@NgModule({
  declarations: [
    AppComponent,
    RemoteMonitoringSetupComponent,
    RemoteMonitoringComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AwBreadcrumbsModule,
    AwSelectModule,
    AwButtonModule,
    AwGalleriaModule,
  ],
  providers: [
    ConfigService,
    DevicesService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigService.loadConfiguration,
      deps: [HttpClient],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
