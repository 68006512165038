import { Injectable } from '@angular/core';
import { swanAIResponseMock } from '../mocks/swan-ai.service.mock';

@Injectable({
  providedIn: 'root'
})
export class SwanAIService {
  constructor() { }

  processSnapshot(): any {
    return swanAIResponseMock();
  }
}
