import { randomIntFromInterval } from '../utils/random';

export const swanAIResponseMock = () => ({
  deviceId: 'e5ff78f3-ac3e-4581-8360-4f8be66110e5',
  s3Url: 's3://fd-image-input/processed/1.jpg',
  imageKiloByteSize: 87,
  imageType: '.jpg',
  pixelHeight: 480,
  pixelWidth: 640,
  dateTime: '09-26-2023 12:59:06',
  person: [
    {
      BoundingBox: {
        Height: randomIntFromInterval(100, 150),
        Left: randomIntFromInterval(50, 150),
        Top: randomIntFromInterval(50, 150),
        Width: randomIntFromInterval(200, 300)
      },
      Confidence: 95,
      position: 'NA'
    },
    {
      BoundingBox: {
        Height: randomIntFromInterval(200, 300),
        Left: randomIntFromInterval(50, 150),
        Top: randomIntFromInterval(50, 150),
        Width: randomIntFromInterval(200, 300)
      },
      Confidence: 54,
      position: 'NA'
    }
  ],
  patient: [
    {
      BoundingBox: {
        Height: randomIntFromInterval(200, 300),
        Left: randomIntFromInterval(50, 150),
        Top: randomIntFromInterval(50, 150),
        Width: randomIntFromInterval(200, 300)
      },
      Confidence: 99,
      position: 'NA'
    }
  ],
  bed: [
    {
      BoundingBox: {
        Height: randomIntFromInterval(200, 300),
        Left: randomIntFromInterval(50, 150),
        Top: randomIntFromInterval(50, 150),
        Width: randomIntFromInterval(200, 300)
      },
      Confidence: 45
    }
  ],
  rail: [
    {
      BoundingBox: {
        Height: randomIntFromInterval(200, 300),
        Left: randomIntFromInterval(50, 150),
        Top: randomIntFromInterval(50, 150),
        Width: randomIntFromInterval(200, 300)
      },
      Confidence: 96
    },
    {
      BoundingBox: {
        Height: randomIntFromInterval(200, 300),
        Left: randomIntFromInterval(50, 150),
        Top: randomIntFromInterval(50, 150),
        Width: randomIntFromInterval(200, 300)
      },
      Confidence: 93
    },
    {
      BoundingBox: {
        Height: randomIntFromInterval(200, 300),
        Left: randomIntFromInterval(50, 150),
        Top: randomIntFromInterval(50, 150),
        Width: randomIntFromInterval(200, 300)
      },
      Confidence: 90
    },
    {
      BoundingBox: {
        Height: randomIntFromInterval(200, 300),
        Left: randomIntFromInterval(50, 150),
        Top: randomIntFromInterval(50, 150),
        Width: randomIntFromInterval(200, 300)
      },
      Confidence: 39
    }
  ],
  statusColor: 'Green',
  StatusMessage: 'Patient on bed'
});
